.Tile {
    background-color: orange;
    width: 5rem;
    height: 5rem;
    opacity: 0.3;
    display: grid;
    place-content: center;
    cursor: pointer;
}

.Tile:hover {
    opacity: 1;
}

.checked{
    opacity:1;
}

.bomb {
    background-color: red;
    opacity: 1;  
}