.responsive {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100vh;
    width: 100%;
}


.container {
    display: grid;
    place-items: center;
    height: auto;
    width: 100%;
    grid-column: 2;
}

.Board {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60rem;
    gap: 0.7rem;
}

aside {
    grid-column: 3;
    display: flex;
    height: 100vh;
    justify-content: flex-end;
}


aside span {
    margin-top: auto;
    text-align: right;
}